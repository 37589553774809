import { createContext } from 'react';

export type CatalogueState = {
	barCode: string;
	category: string;
	product: object;
	products: any[];
	itemWt: string;
	GS: string;
	item: string;
	carat: string;
	price: string;
};

export const DefaultState: CatalogueState = {
	barCode: '',
	category: '',
	product: {},
	products: [],
	itemWt: '',
	GS: '',
	item: '',
	carat: '',
	price: '',
};

type StateContextType = {
	state: CatalogueState;
	setState: React.Dispatch<React.SetStateAction<CatalogueState>>;
};

const StateContext = createContext<StateContextType>({ state: DefaultState, setState: () => { } });

export default StateContext;
