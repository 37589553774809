import { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Pie } from "react-chartjs-2";
import { TextInput, Slider } from "@mantine/core";
import { useForm } from "@mantine/form";

Chart.register(CategoryScale);

const GoldenKey = () => {
	const form = useForm({
		initialValues: {
			installmentAmt: 5000,
			numInstallment: 11,
			makingCharges: 20,
		},

		validate: {
			installmentAmt: (value) => (value <= 5000 ? null : "Amount must be greater than 0"),
		},
	});

	const getDiscount = () => {
		if (form.values.numInstallment < 11) {
			return 0;
		} else {
			return (form.values.installmentAmt * form.values.numInstallment * form.values.makingCharges) / 100;
		}
	};

	const getDiscountPerc = () => Math.trunc((getDiscount() / getWorth()) * 100);
	const getROI = () => Math.trunc((getDiscount() / (form.values.installmentAmt * form.values.numInstallment)) * 100);
	const getWorth = () => getDiscount() + form.values.installmentAmt * form.values.numInstallment;

	const getChartData = () => {
		const Data = [
			{
				label: "Discount Offered",
				amount: getDiscountPerc(),
			},
			{
				label: "Amount Payable",
				amount: 100 - getDiscountPerc(),
			},
		];

		return {
			labels: Data.map((data) => data.label),
			datasets: [
				{
					data: Data.map((data) => data.amount),
					backgroundColor: ["#f3ba2f", "#2a71d0"],
					borderColor: "#555",
					borderWidth: 2,
				},
			],
		};
	};

	const [chartData, setChartData] = useState(getChartData());

	useEffect(() => {
		setChartData(getChartData());
	}, [form.values.installmentAmt, form.values.makingCharges, form.values.numInstallment]);

	return (
		<div className="page-content">
			<h1>Golden Key Plan</h1>
			<div style={{ display: "flex", gap: "30px" }}>
				<form onSubmit={form.onSubmit((values) => console.log(values))} style={{ flex: 1 }}>
					<TextInput
						withAsterisk
						label="Installment Amount:"
						placeholder="1,00,000"
						{...form.getInputProps("installmentAmt")}
					/>

					<div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<label>Making Charges:</label>
							<div>{form.values.makingCharges}%</div>
						</div>
						<Slider {...form.getInputProps("makingCharges")} min={2} max={40} />
					</div>

					<div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<label>Number of Installments:</label>
							<div>{form.values.numInstallment}</div>
						</div>
						<Slider {...form.getInputProps("numInstallment")} min={1} max={11} />
					</div>
				</form>
				<div style={{ flex: "1", marginTop: "0px", display: "flex", flexDirection: "column", gap: "10px" }}>
					<div>
						<label>Total Amount Paid</label>
						<div className="bold">{form.values.installmentAmt * form.values.numInstallment}</div>
					</div>

					<div>
						<label>Total discount amount offered to you</label>
						<div className="bold">{getDiscount()}</div>
					</div>

					<div>
						<label>Buy Jewellery Worth</label>
						<div className="bold">{getWorth()}</div>
					</div>

					<div>
						<label>You would effectively pay</label>
						<div className="bold">{form.values.installmentAmt * form.values.numInstallment}</div>
					</div>

					<div>
						<label>Effective Discount</label>
						<div className="bold">{getDiscountPerc()}%</div>
					</div>

					<div>
						<label>Effective Return on Investment</label>
						<div className="bold">{getROI()}%</div>
					</div>

					<div>
						<label>Annualized ROI</label>
						<div className="bold">{(getROI() * 12) / 6}%</div>
					</div>
				</div>

				<div style={{ flex: 1, maxHeight: "400px" }}>
					<Pie data={chartData} />
				</div>
			</div>
		</div>
	);
};

export default GoldenKey;
