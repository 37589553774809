import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<BrowserRouter>
		<MantineProvider withCSSVariables withGlobalStyles withNormalizeCSS>
			<App />
		</MantineProvider>
	</BrowserRouter>
);

reportWebVitals();
