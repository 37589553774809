function Home() {
	return (
		<div className="page-content">
			<div>Welcome to Bhima Calculator</div>
			<div>Please choose an option from the main menu</div>
		</div>
	);
}

export default Home;
