import { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import StateContext, { CatalogueState, DefaultState } from "./StateContext";
import Home from "./home/home";
import GoldenKey from "./calculators/golden-key";
import Rathna from "./calculators/rathna";

function App() {
	const [state, setState] = useState<CatalogueState>(DefaultState);

	return (
		<StateContext.Provider value={{ state, setState }}>
			<div className="mainmenu">
				<Link to="/">Home</Link>
				<Link to="golden-key">Golden Key</Link>
				<Link to="rathna">Rathna</Link>
			</div>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/golden-key" element={<GoldenKey />} />
				<Route path="/rathna" element={<Rathna />} />
			</Routes>
		</StateContext.Provider>
	);
}

export default App;
